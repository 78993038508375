// ----------------------------------------------------------------------

export const PRODUCT_GENDER_OPTIONS = [
  { label: 'Men', value: 'Men' },
  { label: 'Women', value: 'Women' },
  { label: 'Kids', value: 'Kids' },
];

export const PRODUCT_CATEGORY_OPTIONS = ['Shose', 'Apparel', 'Accessories'];

export const PRODUCT_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];

export const PRODUCT_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

export const PRODUCT_COLOR_NAME_OPTIONS = [
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' },
  { value: 'cyan', label: 'Cyan' },
  { value: 'green', label: 'Green' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'violet', label: 'Violet' },
  { value: 'black', label: 'Black' },
  { value: 'white', label: 'White' },
];

export const PRODUCT_SIZE_OPTIONS = [
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '8.5', label: '8.5' },
  { value: '9', label: '9' },
  { value: '9.5', label: '9.5' },
  { value: '10', label: '10' },
  { value: '10.5', label: '10.5' },
  { value: '11', label: '11' },
  { value: '11.5', label: '11.5' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
];

export const PRODUCT_STOCK_OPTIONS = [
  { value: 'in stock', label: 'In stock' },
  { value: 'low stock', label: 'Low stock' },
  { value: 'out of stock', label: 'Out of stock' },
];

export const PRODUCT_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const PRODUCT_SORT_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High - Low' },
  { value: 'priceAsc', label: 'Price: Low - High' },
];

export const PRODUCT_CATEGORY_GROUP_OPTIONS = [
  {
    group: 'Clothing',
    classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather', 'Accessories'],
  },
  {
    group: 'Tailored',
    classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats', 'Apparel'],
  },
  {
    group: 'Accessories',
    classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'],
  },
];
export enum ProductCheckoutSteps {
  Cart = 'Cart',
  Address = 'Billing & address',
  Greetings = 'Greetings',
  Payment = 'Payment',
}
export const PRODUCT_CHECKOUT_STEPS = [
  ProductCheckoutSteps.Cart,
  ProductCheckoutSteps.Address,
  ProductCheckoutSteps.Greetings,
  ProductCheckoutSteps.Payment,
];

// all products
export const products = [
  {
    id: '3c64b974-e784-4e46-9473-bc62c7439a85',
    slug: 'fresh-line',
    shop: {
      id: '1bd20ec7-5025-47fe-82c4-f4aa41e58fee',
      slug: 'word-wide-wishes',
      user: {
        id: '3ad3f972-b3ae-4b5e-98f0-ef2c800262db',
        email: 'Alison.Hodkiewicz66@yahoo.com',
        phone: '(983) 447-8220 x965',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/602.jpg',
        password: 'i5ycXvQ9UMDXpjg',
        dateOfBirth: '1998-12-09T09:35:27.372Z',
        verified: true,
        name: {
          firstName: 'Lenore',
          lastName: 'Barrows',
        },
      },
      email: 'Jamel.Cummerata@hotmail.com',
      name: 'Word Wide Wishes',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-7.png',
      profilePicture: '/assets/images/faces/propic(6).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Fresh Line',
    brand: null,
    price: 146,
    size: null,
    colors: [],
    discount: 7,
    thumbnail: '/assets/images/Groceries Shop/lime.png',
    images: ['/assets/images/Groceries Shop/lime.png', '/assets/images/Groceries Shop/lime.png'],
    categories: [],
    status: null,
    reviews: [],
    rating: 2,
    for: {
      demo: 'grocery-1',
      type: 'all-products',
    },
  },
  {
    id: '045a8c99-abe3-4f34-8a27-2e27dd82d074',
    slug: 'mini-papers',
    shop: {
      id: 'db3332fe-f103-4f98-bde9-2fc3322918ef',
      slug: 'scroll-through',
      user: {
        id: '151cd4e2-4696-4e46-8c5a-19a198825286',
        email: 'Kaia_Balistreri@hotmail.com',
        phone: '527-907-9120 x4222',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/110.jpg',
        password: 'jwNdvqe8hMlBKrg',
        dateOfBirth: '1971-12-07T16:01:24.592Z',
        verified: true,
        name: {
          firstName: 'Uriel',
          lastName: 'Satterfield',
        },
      },
      email: 'Yvonne_Will@yahoo.com',
      name: 'Scroll Through',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner.png',
      profilePicture: '/assets/images/faces/propic(1).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Mini Papers',
    brand: null,
    price: 110,
    size: null,
    colors: [],
    discount: 9,
    thumbnail: '/assets/images/Groceries Shop/MiniPeppers.png',
    images: [
      '/assets/images/Groceries Shop/MiniPeppers.png',
      '/assets/images/Groceries Shop/MiniPeppers.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 3,
    for: {
      demo: 'grocery-1',
      type: 'all-products',
    },
  },
  {
    id: '60d98a2a-d798-434f-b764-4101c88931d2',
    slug: 'fresh-strawberry',
    shop: {
      id: 'db3332fe-f103-4f98-bde9-2fc3322918ef',
      slug: 'scroll-through',
      user: {
        id: '151cd4e2-4696-4e46-8c5a-19a198825286',
        email: 'Kaia_Balistreri@hotmail.com',
        phone: '527-907-9120 x4222',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/110.jpg',
        password: 'jwNdvqe8hMlBKrg',
        dateOfBirth: '1971-12-07T16:01:24.592Z',
        verified: true,
        name: {
          firstName: 'Uriel',
          lastName: 'Satterfield',
        },
      },
      email: 'Yvonne_Will@yahoo.com',
      name: 'Scroll Through',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner.png',
      profilePicture: '/assets/images/faces/propic(1).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Fresh Strawberry',
    brand: null,
    price: 105,
    size: null,
    colors: [],
    discount: 6,
    thumbnail: '/assets/images/Groceries Shop/strawberry.png',
    images: [
      '/assets/images/Groceries Shop/strawberry.png',
      '/assets/images/Groceries Shop/strawberry.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 5,
    for: {
      demo: 'grocery-1',
      type: 'all-products',
    },
  },
  {
    id: '2fb760be-ad10-476e-b073-36f689721c77',
    slug: 'lemon',
    shop: {
      id: '1bd20ec7-5025-47fe-82c4-f4aa41e58fee',
      slug: 'word-wide-wishes',
      user: {
        id: '3ad3f972-b3ae-4b5e-98f0-ef2c800262db',
        email: 'Alison.Hodkiewicz66@yahoo.com',
        phone: '(983) 447-8220 x965',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/602.jpg',
        password: 'i5ycXvQ9UMDXpjg',
        dateOfBirth: '1998-12-09T09:35:27.372Z',
        verified: true,
        name: {
          firstName: 'Lenore',
          lastName: 'Barrows',
        },
      },
      email: 'Jamel.Cummerata@hotmail.com',
      name: 'Word Wide Wishes',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-7.png',
      profilePicture: '/assets/images/faces/propic(6).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Lemon',
    brand: null,
    price: 232,
    size: null,
    colors: [],
    discount: 7,
    thumbnail: '/assets/images/Groceries Shop/Lemon.png',
    images: ['/assets/images/Groceries Shop/Lemon.png', '/assets/images/Groceries Shop/Lemon.png'],
    categories: [],
    status: null,
    reviews: [],
    rating: 4,
    for: {
      demo: 'grocery-1',
      type: 'all-products',
    },
  },
  {
    id: 'c22478a3-3c57-4e04-85f9-332e966892f5',
    slug: 'fresh-orange',
    shop: {
      id: '5ef1db5d-4c21-4df8-8cad-607df359a550',
      slug: 'anytime-buys',
      user: {
        id: '37b3a95d-349a-480e-8e0d-ea7a671d379c',
        email: 'Noble_Bosco@yahoo.com',
        phone: '1-648-516-7815',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/819.jpg',
        password: '3hbQ6k5TRi46XJH',
        dateOfBirth: '1976-08-15T12:39:16.658Z',
        verified: true,
        name: {
          firstName: 'Destinee',
          lastName: 'Hilpert',
        },
      },
      email: 'Tommie.Kessler2@hotmail.com',
      name: 'Anytime Buys',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-6.png',
      profilePicture: '/assets/images/faces/propic(5).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Fresh Orange',
    brand: null,
    price: 164,
    size: null,
    colors: [],
    discount: 11,
    thumbnail: '/assets/images/Groceries Shop/Orange.png',
    images: [
      '/assets/images/Groceries Shop/Orange.png',
      '/assets/images/Groceries Shop/Orange.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 4,
    for: {
      demo: 'grocery-1',
      type: 'all-products',
    },
  },
  {
    id: '9b8974ed-f0d4-49fb-ad13-b8914b01cf36',
    slug: 'fresh-line',
    shop: {
      id: '71530a6e-b4fe-49e7-a081-d6b1015cc33f',
      slug: 'constant-shoppers',
      user: {
        id: 'bb4e6426-d4ab-4ec9-91a1-aeb08dde3927',
        email: 'Gene_Mertz67@gmail.com',
        phone: '(475) 589-5298',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/618.jpg',
        password: 'THe6QPpXjtn_Itm',
        dateOfBirth: '1998-10-05T11:32:23.421Z',
        verified: true,
        name: {
          firstName: 'Enrico',
          lastName: 'Williamson',
        },
      },
      email: 'Delpha26@yahoo.com',
      name: 'Constant Shoppers',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-4.png',
      profilePicture: '/assets/images/faces/propic(3).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Fresh Line',
    brand: null,
    price: 141,
    size: null,
    colors: [],
    discount: 12,
    thumbnail: '/assets/images/Groceries Shop/lime.png',
    images: ['/assets/images/Groceries Shop/lime.png', '/assets/images/Groceries Shop/lime.png'],
    categories: [],
    status: null,
    reviews: [],
    rating: 2,
    for: {
      demo: 'grocery-1',
      type: 'all-products',
    },
  },
  {
    id: '8f484cbf-d20d-4fb9-8601-d9d15b15d940',
    slug: 'red-apple',
    shop: {
      id: '9c8e3231-1a12-409d-a879-5423e2674ef4',
      slug: 'scarlett-beauty',
      user: {
        id: 'd8caf769-ebfe-47c9-ba6d-c311b753657c',
        email: 'Elise.Rempel44@yahoo.com',
        phone: '564-533-7740',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/118.jpg',
        password: 'wCJPZJF988oENv6',
        dateOfBirth: '2001-11-16T16:49:35.788Z',
        verified: true,
        name: {
          firstName: 'Tyree',
          lastName: 'Bode',
        },
      },
      email: 'Josie.Bosco52@gmail.com',
      name: 'Scarlett Beauty',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/cycle.png',
      profilePicture: '/assets/images/faces/propic.png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Red Apple',
    brand: null,
    price: 145,
    size: null,
    colors: [],
    discount: 7,
    thumbnail: '/assets/images/Groceries Shop/Red apple.png',
    images: [
      '/assets/images/Groceries Shop/Red apple.png',
      '/assets/images/Groceries Shop/Red apple.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 2,
    for: {
      demo: 'grocery-1',
      type: 'all-products',
    },
  },
  {
    id: 'ef0cead5-8319-4092-8fa0-76ee5123c115',
    slug: 'lemon',
    shop: {
      id: '71530a6e-b4fe-49e7-a081-d6b1015cc33f',
      slug: 'constant-shoppers',
      user: {
        id: 'bb4e6426-d4ab-4ec9-91a1-aeb08dde3927',
        email: 'Gene_Mertz67@gmail.com',
        phone: '(475) 589-5298',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/618.jpg',
        password: 'THe6QPpXjtn_Itm',
        dateOfBirth: '1998-10-05T11:32:23.421Z',
        verified: true,
        name: {
          firstName: 'Enrico',
          lastName: 'Williamson',
        },
      },
      email: 'Delpha26@yahoo.com',
      name: 'Constant Shoppers',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-4.png',
      profilePicture: '/assets/images/faces/propic(3).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Lemon',
    brand: null,
    price: 216,
    size: null,
    colors: [],
    discount: 9,
    thumbnail: '/assets/images/Groceries Shop/Lemon.png',
    images: ['/assets/images/Groceries Shop/Lemon.png', '/assets/images/Groceries Shop/Lemon.png'],
    categories: [],
    status: null,
    reviews: [],
    rating: 3,
    for: {
      demo: 'grocery-1',
      type: 'all-products',
    },
  },
  {
    id: 'c9554f35-b32e-4d14-a7c9-2d7c7fb5a5ad',
    slug: 'fresh-line',
    shop: {
      id: 'a3f931aa-241c-499a-b326-56d6afb5e107',
      slug: 'anytime-buys',
      user: {
        id: '79971876-6438-4590-9f3e-fad0fb603f12',
        email: 'Glenna_Rolfson99@yahoo.com',
        phone: '1-214-683-6976',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/836.jpg',
        password: 'H8KGOjHUVYBK2TS',
        dateOfBirth: '1984-02-29T10:16:42.422Z',
        verified: true,
        name: {
          firstName: 'Ward',
          lastName: 'Fisher',
        },
      },
      email: 'Keenan_Gutmann15@gmail.com',
      name: 'Anytime Buys',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-6.png',
      profilePicture: '/assets/images/faces/propic(5).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Fresh Line',
    brand: null,
    price: 146,
    size: null,
    colors: [],
    discount: 7,
    thumbnail: '/assets/images/Groceries Shop/lime.png',
    images: ['/assets/images/Groceries Shop/lime.png', '/assets/images/Groceries Shop/lime.png'],
    categories: [],
    status: null,
    reviews: [],
    rating: 2,
    for: {
      demo: 'grocery-1',
      type: 'popular-products',
    },
  },
  {
    id: '73fd9b2f-46c2-41a6-9990-94481219fc9c',
    slug: 'mini-papers',
    shop: {
      id: 'fd8d012a-452e-4058-ba40-4a95d9ae1147',
      slug: 'scarlett-beauty',
      user: {
        id: '5aa74508-8e8b-47c4-898f-0d189fd15615',
        email: 'Alejandrin0@gmail.com',
        phone: '276.844.6488 x97788',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/316.jpg',
        password: 'FiKdMY6i_3Q9krO',
        dateOfBirth: '1958-02-16T17:48:48.283Z',
        verified: true,
        name: {
          firstName: 'Gudrun',
          lastName: 'Gerlach',
        },
      },
      email: 'Leopold.Shanahan54@gmail.com',
      name: 'Scarlett Beauty',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-9.png',
      profilePicture: '/assets/images/faces/propic(8).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Mini Papers',
    brand: null,
    price: 110,
    size: null,
    colors: [],
    discount: 9,
    thumbnail: '/assets/images/Groceries Shop/MiniPeppers.png',
    images: [
      '/assets/images/Groceries Shop/MiniPeppers.png',
      '/assets/images/Groceries Shop/MiniPeppers.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 3,
    for: {
      demo: 'grocery-1',
      type: 'popular-products',
    },
  },
  {
    id: '66614f8a-045c-4a76-a212-8330d3bb135b',
    slug: 'fresh-strawberry',
    shop: {
      id: 'fd8d012a-452e-4058-ba40-4a95d9ae1147',
      slug: 'scarlett-beauty',
      user: {
        id: '5aa74508-8e8b-47c4-898f-0d189fd15615',
        email: 'Alejandrin0@gmail.com',
        phone: '276.844.6488 x97788',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/316.jpg',
        password: 'FiKdMY6i_3Q9krO',
        dateOfBirth: '1958-02-16T17:48:48.283Z',
        verified: true,
        name: {
          firstName: 'Gudrun',
          lastName: 'Gerlach',
        },
      },
      email: 'Leopold.Shanahan54@gmail.com',
      name: 'Scarlett Beauty',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-9.png',
      profilePicture: '/assets/images/faces/propic(8).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Fresh Strawberry',
    brand: null,
    price: 105,
    size: null,
    colors: [],
    discount: 6,
    thumbnail: '/assets/images/Groceries Shop/strawberry.png',
    images: [
      '/assets/images/Groceries Shop/strawberry.png',
      '/assets/images/Groceries Shop/strawberry.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 5,
    for: {
      demo: 'grocery-1',
      type: 'popular-products',
    },
  },
  {
    id: 'ca45a618-b98e-4e63-8791-104d4058a766',
    slug: 'lemon',
    shop: {
      id: '3201b2a9-a51b-4812-a5d7-9b3d65a414f9',
      slug: 'scarlett-beauty',
      user: {
        id: '56d8e312-5e18-4790-81ca-79cd5e0f6a69',
        email: 'Marc.Johnston86@gmail.com',
        phone: '(760) 911-1417 x0030',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/101.jpg',
        password: 'i7Ws1KpZHftiQ97',
        dateOfBirth: '1949-08-28T18:43:21.405Z',
        verified: true,
        name: {
          firstName: 'Donavon',
          lastName: 'Breitenberg',
        },
      },
      email: 'Sydnie.Dietrich17@gmail.com',
      name: 'Scarlett Beauty',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/cycle.png',
      profilePicture: '/assets/images/faces/propic.png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Lemon',
    brand: null,
    price: 232,
    size: null,
    colors: [],
    discount: 7,
    thumbnail: '/assets/images/Groceries Shop/Lemon.png',
    images: ['/assets/images/Groceries Shop/Lemon.png', '/assets/images/Groceries Shop/Lemon.png'],
    categories: [],
    status: null,
    reviews: [],
    rating: 4,
    for: {
      demo: 'grocery-1',
      type: 'popular-products',
    },
  },

  {
    id: '7a72c27d-2297-417b-8d1a-d183b011947b',
    slug: 'fresh-orange',
    shop: {
      id: 'dc5b35c8-d343-42a0-9c3d-4bef426c87e0',
      slug: 'anytime-buys',
      user: {
        id: '887330ad-cbea-4ca6-99e2-839090e00c90',
        email: 'Marlin46@yahoo.com',
        phone: '209.295.3204 x53363',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/163.jpg',
        password: 'VwU7jmxiFEkWNV3',
        dateOfBirth: '1986-03-26T23:04:15.747Z',
        verified: true,
        name: {
          firstName: 'Maddison',
          lastName: 'Hagenes',
        },
      },
      email: 'Kellen19@yahoo.com',
      name: 'Anytime Buys',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-6.png',
      profilePicture: '/assets/images/faces/propic(5).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Fresh Orange',
    brand: null,
    price: 164,
    size: null,
    colors: [],
    discount: 11,
    thumbnail: '/assets/images/Groceries Shop/Orange.png',
    images: [
      '/assets/images/Groceries Shop/Orange.png',
      '/assets/images/Groceries Shop/Orange.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 4,
    for: {
      demo: 'grocery-1',
      type: 'trending-products',
    },
  },
  {
    id: '6e31e8c4-8a3f-435a-8516-42cc5f85b65c',
    slug: 'fresh-line',
    shop: {
      id: '8e90d0aa-c290-445f-8837-c284cba967d3',
      slug: 'constant-shoppers',
      user: {
        id: 'afa8ff69-d34b-4f74-9769-40e2b1b6d115',
        email: 'Omari83@yahoo.com',
        phone: '(965) 986-5930 x98508',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/981.jpg',
        password: 'rKafFLmkzEEa0nM',
        dateOfBirth: '1981-12-16T09:06:36.105Z',
        verified: true,
        name: {
          firstName: 'Noah',
          lastName: 'Becker',
        },
      },
      email: 'Elise70@hotmail.com',
      name: 'Constant Shoppers',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-4.png',
      profilePicture: '/assets/images/faces/propic(3).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Fresh Line',
    brand: null,
    price: 141,
    size: null,
    colors: [],
    discount: 12,
    thumbnail: '/assets/images/Groceries Shop/lime.png',
    images: ['/assets/images/Groceries Shop/lime.png', '/assets/images/Groceries Shop/lime.png'],
    categories: [],
    status: null,
    reviews: [],
    rating: 2,
    for: {
      demo: 'grocery-1',
      type: 'trending-products',
    },
  },
  {
    id: '1095f8ea-15ab-4a27-9ca6-0804e4539c25',
    slug: 'red-apple',
    shop: {
      id: '9507ef75-b5a1-44be-aee2-c05d27812a6f',
      slug: 'word-wide-wishes',
      user: {
        id: '97b2fb58-a68d-4b18-a944-f51c021361f3',
        email: 'Micheal6@yahoo.com',
        phone: '661.712.5647 x999',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/63.jpg',
        password: 'QoKs4OZUIFRA7BF',
        dateOfBirth: '1972-05-16T10:29:29.707Z',
        verified: true,
        name: {
          firstName: 'Alexane',
          lastName: 'Krajcik',
        },
      },
      email: 'Nicholas_Parker@gmail.com',
      name: 'Word Wide Wishes',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-7.png',
      profilePicture: '/assets/images/faces/propic(6).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Red Apple',
    brand: null,
    price: 145,
    size: null,
    colors: [],
    discount: 7,
    thumbnail: '/assets/images/Groceries Shop/Red apple.png',
    images: [
      '/assets/images/Groceries Shop/Red apple.png',
      '/assets/images/Groceries Shop/Red apple.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 2,
    for: {
      demo: 'grocery-1',
      type: 'trending-products',
    },
  },
  {
    id: '25b7abab-802d-433e-8cc7-ab8f39bec9bc',
    slug: 'lemon',
    shop: {
      id: 'd2fdd126-e4b6-4125-9354-3b975af2b1aa',
      slug: 'cybershop',
      user: {
        id: '62696303-3645-4570-b492-84e1a10a6646',
        email: 'Trey99@gmail.com',
        phone: '309-679-0081 x2251',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/559.jpg',
        password: 'kncHZmPWy39ETSN',
        dateOfBirth: '1970-08-21T22:52:43.975Z',
        verified: true,
        name: {
          firstName: 'Santiago',
          lastName: 'Legros',
        },
      },
      email: 'Cathrine3@yahoo.com',
      name: 'Cybershop',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-8.png',
      profilePicture: '/assets/images/faces/propic(7).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Lemon',
    brand: null,
    price: 216,
    size: null,
    colors: [],
    discount: 9,
    thumbnail: '/assets/images/Groceries Shop/Lemon.png',
    images: ['/assets/images/Groceries Shop/Lemon.png', '/assets/images/Groceries Shop/Lemon.png'],
    categories: [],
    status: null,
    reviews: [],
    rating: 3,
    for: {
      demo: 'grocery-1',
      type: 'trending-products',
    },
  },
  {
    id: 'd19eebd9-6754-49db-a50f-c58aab30436d',
    slug: 'offer-fruits',
    shop: {
      id: '259297f8-42b8-43f4-a0a6-7a1076adf7b7',
      slug: 'scarlett-beauty',
      user: {
        id: '33771660-17a7-48d2-95d7-c7939c5acb32',
        email: 'Barbara.Batz84@gmail.com',
        phone: '1-455-596-2418 x219',
        avatar:
          'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/972.jpg',
        password: 'awPzYdG2vtfalkA',
        dateOfBirth: '1962-09-25T06:54:11.955Z',
        verified: true,
        name: {
          firstName: 'Newton',
          lastName: 'Sipes',
        },
      },
      email: 'Angelo5@hotmail.com',
      name: 'Scarlett Beauty',
      phone: '(613) 343-9004',
      address: '845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark',
      verified: false,
      coverPicture: '/assets/images/banners/banner-9.png',
      profilePicture: '/assets/images/faces/propic(8).png',
      socialLinks: {
        facebook: null,
        youtube: null,
        twitter: null,
        instagram: null,
      },
    },
    title: 'Offer Fruits',
    brand: null,
    price: 198,
    size: null,
    colors: [],
    discount: 12,
    thumbnail: '/assets/images/Groceries Shop/Offer Card.png',
    images: [
      '/assets/images/Groceries Shop/Offer Card.png',
      '/assets/images/Groceries Shop/Offer Card.png',
    ],
    categories: [],
    status: null,
    reviews: [],
    rating: 4,
    for: {
      demo: 'grocery-1',
      type: 'trending-products',
    },
  },
];

export const mainCarouselItem = [
  {
    id: 1,
    title: 'Lemon',
    imgUrl: '/assets/images/Groceries Shop/Orange.png',
    buttonLik: '',
    buttonText: 'Shop Now',
    description:
      'What is Lorem Ipsum?\n' +
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: 2,
    title: 'Fresh Line',
    imgUrl: '/assets/images/Groceries Shop/lime.png',
    buttonLik: '',
    buttonText: 'Shop Now',
    description:
      'What is Lorem Ipsum?\n' +
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: 3,
    title: 'Lemons',
    imgUrl: '/assets/images/Groceries Shop/Lemon.png',
    buttonLik: '',
    buttonText: 'Shop Now',
    description:
      'What is Lorem Ipsum?\n' +
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: 5,
    title: 'Red apple',
    imgUrl: '/assets/images/Groceries Shop/Red apple.png',
    buttonLik: '',
    buttonText: 'Shop Now',
    description:
      'What is Lorem Ipsum?\n' +
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: 6,
    title: 'Vagitable',
    imgUrl: '/assets/images/Groceries Shop/vagitable.png',
    buttonLik: '',
    buttonText: 'Shop Now',
    description:
      'What is Lorem Ipsum?\n' +
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

export const productsB2b = [
  {
    id: '1',
    img: '/assets/images/happy-gift/products/1.png',
    brand: '/assets/images/happy-gift/products/brands/1.png',
    title: 'סאפ koning 300 כולל משלוח עד הבית',
    price: 990,
    gift: false,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/1.png',
      '/assets/images/happy-gift/products/1.png',
      '/assets/images/happy-gift/products/1.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '2',
    img: '/assets/images/happy-gift/products/2.png',
    brand: '/assets/images/happy-gift/products/brands/2.png',
    title: 'טלוויזיה חכמה 32 אינץ',
    price: 755,
    gift: false,
    type: 'food',
    category: 'food',
    area: 'food',
    images: [
      '/assets/images/happy-gift/products/2.png',
      '/assets/images/happy-gift/products/2.png',
      '/assets/images/happy-gift/products/2.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '3',
    img: '/assets/images/happy-gift/products/3.png',
    brand: '/assets/images/happy-gift/products/brands/3.png',
    title: "כניסה למתחם הנינג'ה- SWING ראשון לציון",
    price: 69,
    gift: false,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/3.png',
      '/assets/images/happy-gift/products/3.png',
      '/assets/images/happy-gift/products/3.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '4',
    img: '/assets/images/happy-gift/products/4.png',
    brand: '/assets/images/happy-gift/products/brands/6.png',
    title: "כניסה למתחם הנינג'ה- SWING ראשון לציון",
    price: 69,
    gift: true,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/4.png',
      '/assets/images/happy-gift/products/4.png',
      '/assets/images/happy-gift/products/4.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '5',
    img: '/assets/images/happy-gift/products/5.png',
    brand: '/assets/images/happy-gift/products/brands/4.png',
    title: '',
    price: 75,
    gift: false,
    type: 'food',
    category: 'food',
    area: 'food',
    images: [
      '/assets/images/happy-gift/products/5.png',
      '/assets/images/happy-gift/products/5.png',
      '/assets/images/happy-gift/products/5.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '6',
    img: '/assets/images/happy-gift/products/11.png',
    brand: '/assets/images/happy-gift/products/brands/5.png',
    title: 'שואב שוטף רובוטי HOBOT\n',
    price: 2226,
    gift: false,
    type: 'food',
    category: 'food',
    area: 'food',
    images: [
      '/assets/images/happy-gift/products/11.png',
      '/assets/images/happy-gift/products/11.png',
      '/assets/images/happy-gift/products/11.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '7',
    img: '/assets/images/happy-gift/products/17.png',
    brand: '/assets/images/happy-gift/products/brands/6.png',
    title: 'מאוורר נטען אלחוטי, דגם VR1400 של Relax',
    price: 459,
    gift: false,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/17.png',
      '/assets/images/happy-gift/products/17.png',
      '/assets/images/happy-gift/products/17.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '8',
    img: '/assets/images/happy-gift/products/16.png',
    brand: '/assets/images/happy-gift/products/brands/3.png',
    title: "כניסה למתחם הנינג'ה- SWING ראשון לציון\n",
    price: 70,
    gift: true,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/16.png',
      '/assets/images/happy-gift/products/16.png',
      '/assets/images/happy-gift/products/16.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '9',
    img: '/assets/images/happy-gift/products/12.png',
    brand: '/assets/images/happy-gift/products/brands/3.png',
    title: 'שואב אבק רובוטי Roidmi\n',
    price: 1590,
    gift: false,
    type: 'food',
    category: 'food',
    area: 'food',
    images: [
      '/assets/images/happy-gift/products/12.png',
      '/assets/images/happy-gift/products/12.png',
      '/assets/images/happy-gift/products/12.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '10',
    img: '/assets/images/happy-gift/products/13.png',
    brand: '/assets/images/happy-gift/products/brands/1.png',
    title: 'אופניים חשמליים KONING SPIDER כולל משלוח עד הבית',
    price: 3200,
    gift: false,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/13.png',
      '/assets/images/happy-gift/products/13.png',
      '/assets/images/happy-gift/products/13.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '12',
    img: '/assets/images/happy-gift/products/14.png',
    brand: '/assets/images/happy-gift/products/brands/1.png',
    title: 'שלמו פחות חייכו יותר',
    price: 80,
    gift: true,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/14.png',
      '/assets/images/happy-gift/products/14.png',
      '/assets/images/happy-gift/products/14.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
  },
  {
    id: '11',
    img: '/assets/images/happy-gift/products/15.png',
    brand: '/assets/images/happy-gift/products/brands/4.png',
    title: 'קורקינט פעלולים MAYO SCOOT COMP\n',
    price: 169,
    gift: false,
    type: 'food',
    category: 'food',
    area: 'food',
    images: [
      '/assets/images/happy-gift/products/15.png',
      '/assets/images/happy-gift/products/15.png',
      '/assets/images/happy-gift/products/15.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '13',
    img: '/assets/images/happy-gift/products/5.png',
    brand: '/assets/images/happy-gift/products/brands/2.png',
    title: 'RENE בית קפה\n',
    price: 150,
    gift: true,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/5.png',
      '/assets/images/happy-gift/products/5.png',
      '/assets/images/happy-gift/products/5.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '14',
    img: '/assets/images/happy-gift/products/6.png',
    brand: '/assets/images/happy-gift/products/brands/6.png',
    title: 'מייבש שיער 2100W\n',
    price: 365,
    gift: false,
    type: 'food',
    category: 'food',
    area: 'food',
    images: [
      '/assets/images/happy-gift/products/6.png',
      '/assets/images/happy-gift/products/6.png',
      '/assets/images/happy-gift/products/6.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '5',
    img: '/assets/images/happy-gift/products/7.png',
    brand: '/assets/images/happy-gift/products/brands/1.png',
    title: 'סט 3 מזוודות קשיחות ואיכותיות מבית Mor Switzerland',
    price: 16.19,
    gift: false,
    type: 'home',
    category: 'home',
    area: 'home',
    images: [
      '/assets/images/happy-gift/products/7.png',
      '/assets/images/happy-gift/products/7.png',
      '/assets/images/happy-gift/products/7.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
  {
    id: '16',
    img: '/assets/images/happy-gift/products/8.png',
    brand: '/assets/images/happy-gift/products/brands/5.png',
    title: 'בלנדר שייקר אישי',
    price: 16.19,
    gift: false,
    type: 'food',
    category: 'food',
    area: 'food',
    images: [
      '/assets/images/happy-gift/products/8.png',
      '/assets/images/happy-gift/products/8.png',
      '/assets/images/happy-gift/products/8.png',
    ],
    description:
      'רנה היא רשת בתי קפה פופולארית ומוכרת מאוד באזור הצפון. הרשת הוקמה בשנת 2003 ומלווה את אוכלוסיית הצפון באזורי עסקים, אזורי בילוי וקניונים מרכזיים. הרשת ידועה באווירה והעיצוב החדשני והצעיר של סניפיה, המנות הגדולות המוגשות במחיר סביר, ובאווירה צעירה ודינמית. לאחר פניות רבות של אנשים המעוניינים לקבל זיכיון מהרשת, החליטה הרשת להרחיב את פעילותה לפרישה ארצית באמצעות מודת הזכיינות, וכעת מציעה את ההזדמנות ליזמים צעירים המעוניינים לפתוח סניף של הרשת בכל רחבי הארץ.',
    phone: '085 222 222',
    link: 'לאתר בית העסק לחץ כאן',
  },
];
