export const allCategories = [
  {
    id: '6df3771a-7075-492d-8f1f-c8358cd6345f',
    name: 'Automobile',
    icon: null,
    slug: 'automobile',
    image: '/assets/images/products/Automotive/1.Ford2019.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '25a4d207-512d-4001-b531-1cded6665f88',
    name: 'Car',
    icon: null,
    slug: 'car',
    image: '/assets/images/products/Automotive/2.Audi2017.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '79c89024-dff2-4a00-9ce5-251145dfc18f',
    name: 'Fashion',
    icon: null,
    slug: 'fashion',
    image: '/assets/images/products/Fashion/Jewellery/8.IndianPearlThreadEarrings.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '6097cbe8-eba5-4462-aea0-b0d53f9dd0ed',
    name: 'Mobile',
    icon: null,
    slug: 'mobile',
    image: '/assets/images/products/Electronics/25.VivoMobiles.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: 'e6660169-fbba-4724-81e3-8ffae6ff3707',
    name: 'Laptop',
    icon: null,
    slug: 'laptop',
    image: '/assets/images/products/Fashion/Shoes/11.Flowwhite.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '158d635a-76fd-425b-a158-1e8d8cd97542',
    name: 'Desktop',
    icon: null,
    slug: 'desktop',
    image: '/assets/images/products/Groceries/1.SaktiSambarPowder.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '0b68c451-0d4d-47ac-83e4-33651ae3d11f',
    name: 'Tablet',
    icon: null,
    slug: 'tablet',
    image: '/assets/images/products/Groceries/14.ACIProducts.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '7a069456-f06a-4688-994e-ddb620f9f67c',
    name: 'Eat',
    icon: null,
    slug: 'eat',
    image: '/assets/images/products/Groceries/27.SardinesPack.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '5144e1aa-c745-44ae-a40b-a41a35229d27',
    name: 'Electronics',
    icon: null,
    slug: 'electronics',
    image: '/assets/images/products/Health&Beauty/12.BeautySocietyantiacnemask.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: 'b75056ae-be37-4061-a06e-9db6a9fda38b',
    name: 'Furniture',
    icon: null,
    slug: 'furniture',
    image: '/assets/images/Furniture Shop/brown-chair.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '282a5ae0-9d26-4edf-848b-ffb66d949bbe',
    name: 'Camera',
    icon: null,
    slug: 'camera',
    image: '/assets/images/categories/cat-6.jpg',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
  {
    id: '3c36096b-73b4-4c3e-a768-f846cc889fd5',
    name: 'Electronics',
    icon: null,
    slug: 'electronics',
    image: '/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png',
    description: null,
    parent: [],
    for: { demo: 'market-1', type: 'categories' },
  },
];
